<template>
  <router-link to="/product/freeUse" class="free" :style="styleObject">
    <p class="title">数据驱动，让纸板厂销售管理更轻松，拿单更容易</p>
    <dj-button
      class="btn"
      type="dark"
      href="/product/freeUse?source=indexModule"
    >
      免费试用
    </dj-button>
  </router-link>
</template>

<script>
import utils from '@/utils/utils'

export default {
  name: 'free',
  props: {
    freeUse: {
      type: Object,
      default: () => {
        return {
          picList: []
        }
      }
    }
  },
  data() {
    return {
      styleObject: {}
    }
  },
  created() {
    if (this.freeUse.picList.length > 0) {
      this.styleObject = {
        backgroundImage: `url(${utils.setOssUrl(
          this.freeUse.picList[0].picture
        )})`
      }
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.free {
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../../assets/images/bg_default_free.png');
  background-position: center;
  background-size: cover;
  .title {
    font-size: 32px;
    font-weight: 400;
    color: #000;
    line-height: 45px;
  }
  .btn {
    margin-top: 50px;
    /deep/a.button {
      width: 224px;
      border-radius: 4px;
    }
  }
}
@media (min-width: 750px) and (max-width: 960px) {
}
@media (min-width: 961px) {
}
</style>
